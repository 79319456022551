import { Suspense } from 'react';
import './App.css';
import MainPage from './Page/Main';

function App() {
  return (
    <Suspense fallback="loading">
      <MainPage />
    </Suspense>
  );
}

export default App;
