import { useEffect } from 'react';
import PrintTicketPageCSS from "./PrintTicketPage.module.css"
import { modelSpeak, pointBotLeft, pointBotRight, resetModel, setLive2DScale } from "../../Helper/UnityLive2DController"

import { Link, useNavigate } from 'react-router-dom';
import BookingNumberHeader from '../../Component/BookingNumberHeader';
import { useTranslation } from 'react-i18next';

import { DJANGO_API, KIOSK_API } from '../../Constant/Api';
import { useBookingNumber } from '../../Context/BookingNumber';
import { useAppointment } from '../../Context/Appointment';

async function printTicket(bookingNumber, appointment) {
  try {
    const { room:roomNumber } = appointment;
    console.debug('room', roomNumber)
    // generate ticket
    const ticketRes = await fetch(`${DJANGO_API}/v2/api/ticket/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ booking_ref: bookingNumber }),
    });
    const { ticket_img } = await ticketRes.json();
    console.debug('ticket_img', ticket_img)
    const response = await fetch(`${KIOSK_API}/ticket`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ticketImg: ticket_img }),
    });
    console.log(response.json());
  } catch (e) {
    console.error(e);
  }
}

const PrintTicketPage = ({ setSpeech }) => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const bookingNubmer = useBookingNumber();
  const appointmentContext = useAppointment();

  const { room:roomNumber } = appointmentContext;
  
  useEffect(() => {
    setSpeech(t('printTicket.welcome'));
    setLive2DScale(-0.75, 0.75, 0.75);
    pointBotRight();
    modelSpeak();
    printTicket(bookingNubmer, appointmentContext);
    const timeoutId = setTimeout(() => navigate('/'), 20000);

    return () => {
      clearTimeout(timeoutId);
    }
  }, [])

  return (
    <>
      <BookingNumberHeader />
      <div className = {PrintTicketPageCSS.title}>
        {t('printTicket.message0') + roomNumber + t('printTicket.message1') }
      </div>
      <Link to="/">
        <div className={PrintTicketPageCSS.backHomeButton}>
          <div className={PrintTicketPageCSS.backHomeButtonText}>
            {t('global.homePage')}
          </div>
        </div>
      </Link>
    </>
  );

}

export default PrintTicketPage;