import { useEffect, useState, useReducer } from 'react';
import BookingPageCSS from "./BookingPage.module.css"
import { modelSpeak, pointMidLeft, pointTopLeft, setLive2DScale } from "../../Helper/UnityLive2DController"
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import "./Keyboard.css"

import { useNavigate, Link } from 'react-router-dom';
import { useBookingNumber, useBookingNumberUpdate } from '../../Context/BookingNumber';

import { DJANGO_API } from '../../Constant/Api';
import { useTranslation } from 'react-i18next';

import { Textfit } from 'react-textfit';
import { useAppointmentUpdate } from '../../Context/Appointment';

function barcodeReducer(state, action) {
  switch ( action.type ) {
    case 'incoming char':   return [...state, action.key];
    case 'reset':           return [];
    case 'backspace':       return state.slice(0, -1);
    case 'null terminate':  return [...state, null];
    case 'set to':          return [...action.chars];
    default:                throw new Error();
  }
}

const BookingPage = ({ setSpeech }) => {

  const { t, i18n } = useTranslation();

  const [barcode, barcodeDispatch] = useReducer(barcodeReducer, []);
  const [isConfirmSubPage, setIsConfirmSubPage] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const navigate = useNavigate();

  const confirmedBookingNumber = useBookingNumber();
  const setConfirmedBookingNumber = useBookingNumberUpdate();
  const setAppointment = useAppointmentUpdate();

  useEffect(async () => {
    if ( barcode[barcode.length - 1] === null ) {
      await barcodeVerificationRoutine();
    }
  }, [barcode]);

  const barcodeVerificationRoutine = async () => {
    try {
      console.debug('barcode length', barcode.length)
      if ( barcode.length === 1 ) {
        throw new Error('empty barcode');
      }
      setIsFetching(true);
      const response = await fetch(`${DJANGO_API}/v2/api/appointment?booking_ref=${barcode.join('').toUpperCase()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
        },
      });
      const appointments = await response.json();
      console.debug('appointments', appointments)
      if ( appointments.count === 0 ) {
        throw new Error('booking not found');
      }
      const appointment = appointments.results[0]; // TODO: booking_ref might not be unique
      const { booking_ref } = appointment;
      barcodeDispatch({ type: 'set to', chars: booking_ref.split('') });
      setAppointment(appointment);
      setIsFetching(false);
      setIsConfirmSubPage(true);
      modelSpeak();
      setSpeech(t('booking.instructScan'));
    } catch (e) {
      setIsFetching(false);
      barcodeDispatch({type: 'reset'});
      modelSpeak();
      setSpeech(t('booking.error'));
    }
  }

  // barcode scanner logic
  const handleKeyPress = async event => {
    if ( isFetching ) {
      return;
    }
    const { key, keyCode } = event;
    console.debug(key, keyCode)
    if (keyCode === 32 || ( 65 <= keyCode && keyCode <= 90) || (97 <= keyCode && keyCode <= 122) || (48 <= keyCode && keyCode <= 57)) {
      barcodeDispatch({type: 'incoming char', key});
    } else if (keyCode === 13) {
      barcodeDispatch({type: 'null terminate'});
    }
  }
  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    }
  }, [handleKeyPress]);
  // hint: https://stackoverflow.com/a/55566585

  const handleVirtualKeyPress = async key => {
    if ( isFetching ) {
      return;
    }
    switch (key) {
      case '{enter}': return barcodeDispatch({type: 'null terminate'});
      case '{bksp}':  return barcodeDispatch({type: 'backspace'});
      default:        return barcodeDispatch({type: 'incoming char', key});
    }
  }

  const cancelButton = () => {
    barcodeDispatch({type: 'reset'});
    setIsConfirmSubPage(false);
  }

  const confirmButton = () => {
    setConfirmedBookingNumber(barcode.join(''));
    navigate('/temperature');
  }

  // when this page load, animate the character
  useEffect(() => {
    setSpeech(t('booking.instructScan'));
    setIsConfirmSubPage(false);
    setLive2DScale(0.75, 0.75, 0.75);
    pointMidLeft();
    modelSpeak();
  }, []);

  return (
    <>
      {
        !isConfirmSubPage ?
          <>
            <h3>{t('booking.header')}</h3>

            {/* TODO: strink font size if too long? */}
            <div className={BookingPageCSS.inputBox}>
              <Textfit mode="single">
                {barcode.join('').toUpperCase()}
              </Textfit>
            </div>

            <div className={BookingPageCSS.keyboardContainer}>
              <Keyboard
                theme={"hg-theme-default myTheme1"}
                layout={{
                  default: [
                    '1 2 3 4 5 6 7 8 9 0',
                    'Q W E R T Y U I O P {bksp}',
                    'A S D F G H J K L',
                    'Z X C V B N M {enter}',
                  ],
                }}
                display={
                  {
                    "{bksp}": "⌫",
                    "{enter}": t('booking.enterBtn'),
                  }
                }

                onKeyPress={handleVirtualKeyPress}
              />
            </div>
            {isFetching &&
              <h2>{t('booking.requestDB')}</h2>
            }
          </>
          :
          <>
            <h3>
              {t('booking.confirmBooking')}
            </h3>
            
            <div className={BookingPageCSS.inputBox}>
              <Textfit mode="single">
                {barcode.join('').toUpperCase()}
              </Textfit>
            </div>
            <div className={BookingPageCSS.buttonContainer}>
              <div className={BookingPageCSS.cancelButton} onClick={cancelButton}>
                <div className={BookingPageCSS.buttonText}>
                  {t('booking.cancel')}
                </div>
              </div>
              <div className={BookingPageCSS.confirmButton} onClick={confirmButton}>
                <div className={BookingPageCSS.buttonText}>
                  {t('booking.confirm')}
                </div>
              </div>
            </div>
          </>
      }
      <Link to="/">
        <div className={BookingPageCSS.backHomeButton}>
          <div className={BookingPageCSS.backHomeButtonText}>
            {t('global.homePage')}
          </div>
        </div>
      </Link>
    </>
  );

}

export default BookingPage;