import React, { useContext, useState } from "react";

const AppointmentContext = React.createContext();
const AppointmentUpdateContext = React.createContext();

export function useAppointment() {
  return useContext(AppointmentContext);
}

export function useAppointmentUpdate() {
  return useContext(AppointmentUpdateContext);
}

export const AppointmentProvider = ({children}) => {

  const [Appointment, setAppointment] = useState('');

  function changeAppointment(newBooking) {
    setAppointment(newBooking);
  }

  return(
    <AppointmentContext.Provider value={Appointment}>
      <AppointmentUpdateContext.Provider value={changeAppointment}>
        {children}
      </AppointmentUpdateContext.Provider>
    </AppointmentContext.Provider>
  ) 
}