import React, { useEffect, useState } from "react";
import { Question, IQuestionComponent } from "../question-factory";

const CheckboxQuestion:IQuestionComponent = ({ question, onSelect:setAnswers }) => {

  const handleCheck = (optionId:number) => {
    const clone = [...question.answers];
    clone[optionId] = ! clone[optionId];
    setAnswers(clone);
  }

  const [error, setError] = useState<false|string>(false);
  useEffect(() => {
    isAnswered(question, setError);
  }, [question]);

  return (
    <div>
      <h3>{question.questionString}</h3>
      {question.options.map((option, index) =>
        <React.Fragment key={index}>
          <input
            type="checkbox"
            checked={question.answers[index]===true}
            onChange={() => handleCheck(index)}
            key={index}
          />
          {option}
          <br />
        </React.Fragment>
      )}
      {error &&
        <div style={{border: '5px solid red'}}>
          {error}
        </div>
      }
    </div>
  );
}
const isAnswered = (question:Question, setError?:(q:false|string)=>void) => {
  const { minChoice, maxChoice, answers } = question;
  const checked = answers.filter(_=>_===true).length;
  switch ( true ) {
    case minChoice <= checked && checked <= maxChoice:  setError && setError(false);      return true;
    case checked < minChoice:                           setError && setError('揀得太少');   return false;
    case           maxChoice < checked:                 setError && setError('揀得太多');   return false;
    default:                                            setError && setError('unknown');  return false;
  }
}
CheckboxQuestion.isAnswered = (question:Question) => isAnswered(question);
CheckboxQuestion.isThisType = (question:Question) => {
  const { minChoice, maxChoice } = question;
  switch ( true ) {
    case minChoice === 1 && maxChoice === 1:  return false; // RadioQuestion
    case minChoice > maxChoice:               return false; // invalid CheckboxQuestion
    case maxChoice >= minChoice:              return true;
    default:                                  return false;
  }
};

export default CheckboxQuestion;