import React, { useState, useEffect } from 'react';
import Unity from "react-unity-webgl";
import MainCSS from "./Main.module.css"

import { UnityLive2DContext } from "../Constant/UnityLive2DContext"

import 'react-simple-keyboard/build/css/index.css';

import ChooseServicePage from './ChooseService/ChooseServicePage';
import BookingPage from './Booking/BookingPage';
import QuestionnairePage from './Questionnaire/QuestionnairePage';
import TemperaturePage from "./Tempeture/TemperaturePage"
import PrintTicketPage from "./PrintTicket/PrintTicketPage"
import RequireAssistancePage from "./RequireAssistance/ReqireAssistancePage"
import { setLive2DPosition, setLive2DScale } from '../Helper/UnityLive2DController';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from '../Header/Header';
import SpeechBubble from './SpeechBubble';
import { BookingNumberProvider, useBookingNumber } from '../Context/BookingNumber';
import { AppointmentProvider } from '../Context/Appointment';

export const Main = () => {

  const [speech, setSpeech] = useState('');
  const confirmedBookingNumber = useBookingNumber();

  // Init
  useEffect(() => {
    // Init Unity when fully loaded
    UnityLive2DContext.on("GameLoaded", () => {
      setLive2DPosition(0.20, -0.75, 0);
      setLive2DScale(0.75, 0.75, 0.75);
    });
  }, []);

  const pageProps = {
    setSpeech,
    // for futhre extension when more set functions need to pass on
  };

  // Component Return
  return (
    <>
      <div className={MainCSS.everythingContainer}>
        <AppointmentProvider>
          <BookingNumberProvider>

            <Header />

            {/* main web pages */}
            <div className={MainCSS.pageContainer}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<ChooseServicePage {...pageProps} />}  ></Route>
                  <Route path="booking" element={<BookingPage {...pageProps} />}        ></Route>
                  <Route path="temperature" element={<TemperaturePage {...pageProps} />}    ></Route>
                  <Route path="questionnaire" element={<QuestionnairePage {...pageProps} />}  ></Route>
                  <Route path="print-ticket" element={<PrintTicketPage {...pageProps} />}    ></Route>
                  <Route path="require-assistance" element={<RequireAssistancePage {...pageProps} />}    ></Route>
                </Routes>
              </BrowserRouter>
            </div>

            <div className={MainCSS.speechBubbleContainer}>
              {speech && <SpeechBubble text={speech} />}
            </div>

            {/* unity live2d background */}
            <div className={MainCSS.unityContainer}>
              <Unity
                unityContext={UnityLive2DContext}
                style={{
                  height: "100vh",
                  width: "100vw",
                  background: "grey",
                }}
              />
            </div>

            {/* TODO: Language Buttons */}
          </BookingNumberProvider>
        </AppointmentProvider>

      </div>
    </>
  );

}

export default Main;