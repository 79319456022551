import { useTranslation } from 'react-i18next';
import { useBookingNumber } from '../Context/BookingNumber';
import css from './BookingNumberHeader.module.css';

const BookingNumberHeader = () => {
  const confirmedBookingNumber = useBookingNumber();

  const { t } = useTranslation();

  return (
    <div className={css.text}>
      {t('global.yourBookingRef')}{confirmedBookingNumber}
    </div>
  );
}

export default BookingNumberHeader;