import { FunctionComponent } from 'react';
import RadioQuestion from './question-types/RadioQuestion';
import CheckboxQuestion from './question-types/CheckboxQuestion';

type RawOption = {
  order: number;
  option_string: string;
}
type RawQuestion = {
  min_choice: number;
  max_choice: number;
  question_string: string;
  order: number;
  options: RawOption[];
}

export type Question = {
  minChoice: number;
  maxChoice: number;
  questionString: string;
  options: string[];
  answers: boolean[];  // initial design: number[] for RadioQuestion; boolean[] for CheckboxQuestion. seems like can use the same data-structure
};

type Props = {
  question: Question;
  onSelect: (answers:boolean[]) => void;
}

export interface IQuestionComponent extends React.FC<Props> {
  isAnswered: (question:Question) => boolean;
  isThisType: (question:Question) => boolean;
}

export default function getQuestionComponent(question:Question): FunctionComponent<Props> {
  switch ( true ) {
    case RadioQuestion.isThisType(question):    return RadioQuestion;
    case CheckboxQuestion.isThisType(question): return CheckboxQuestion;
    default:                                  throw new Error('unhandled question component');
  }
}
