import React, { useContext, useState } from "react";

const BookingNumberContext = React.createContext();
const BookingNumberUpdateContext = React.createContext();

export function useBookingNumber() {
  return useContext(BookingNumberContext);
}

export function useBookingNumberUpdate() {
  return useContext(BookingNumberUpdateContext);
}

export const BookingNumberProvider = ({children}) => {

  const [bookingNumber, setBookingNumber] = useState('');

  function changeBookingNumber(newBooking) {
    setBookingNumber(newBooking);
  }

  return(
    <BookingNumberContext.Provider value={bookingNumber}>
      <BookingNumberUpdateContext.Provider value={changeBookingNumber}>
        {children}
      </BookingNumberUpdateContext.Provider>
    </BookingNumberContext.Provider>
  ) 
}