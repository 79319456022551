import React, { useState, useEffect } from 'react';
import QuestionnairePageCSS from "./QuestionnairePage.module.css"
import { resetModel, pointTopLeft, modelSpeak, pointMidLeft2, setLive2DScale, pointMidLeft } from "../../Helper/UnityLive2DController"

import getQuestionComponent from './question-factory';
import { useNavigate } from 'react-router';

import { DJANGO_API } from '../../Constant/Api';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBookingNumber } from '../../Context/BookingNumber';
import BookingNumberHeader from '../../Component/BookingNumberHeader';
import { useAppointment } from '../../Context/Appointment';

const QuestionnairePage = ({ setSpeech }) => {

  const { t, i18n } = useTranslation();

  const bookingNumber = useBookingNumber();

  useEffect(() => {
    setSpeech(t('questionnaire.welcome'));
    setLive2DScale(0.75, 0.75, 0.75);
    pointMidLeft();
    modelSpeak();
  }, []);

  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [activeQuestionId, setActiveQuestionId] = useState(null);
  const appointment = useAppointment();

  useEffect(() => {
    // load question
    const questions = appointment.question_book.questions
    .map(q => ({
      order: q.order,
      minChoice: 1,
      maxChoice: 1,
      questionString: q.locale_question,
      options: q.options.map(o => o.option_string[i18n.language]),
      answers: q.options.map(() => false),
    }))
    .sort((a,b) => a.order < b.order);
    setQuestions(questions);
    setActiveQuestionId(0);

    // loading
    setLoading(false);
  }, []);

  useEffect(() => {
    // error handling, if no question
    if (questions.length === 0) {
      setActiveQuestionId(null);
    }

    // else, no error for loading question, but activeQuestionId point to null
    if (activeQuestionId === null) {
      // reset active question id
      setActiveQuestionId(0);
    }
  }, [questions]);

  const IQuestion = activeQuestionId === null ? undefined : getQuestionComponent(questions[activeQuestionId]);

  const isFirstQuestion = activeQuestionId === 0;
  const isLastQuestion = activeQuestionId === questions.length - 1;
  const isAnswered = activeQuestionId !== null && IQuestion.isAnswered(questions[activeQuestionId]);

  const handleSelect = (selectedOptionIds) => {
    const updatedActiveQuestion = {
      ...questions[activeQuestionId],
      answers: selectedOptionIds,
    };
    const updatedQuestions = [
      ...questions.slice(0, activeQuestionId),
      updatedActiveQuestion,
      ...questions.slice(activeQuestionId + 1),
    ];
    setQuestions(updatedQuestions);
  }

  const handlePrevQuestion = (e) => {
    if (activeQuestionId > 0) {
      setActiveQuestionId(activeQuestionId - 1);
    } else {
      throw new Error('unexpected prev question');
    }
  }
  const handleNextQuestion = (e) => {
    if (activeQuestionId < questions.length - 1) {
      setActiveQuestionId(activeQuestionId + 1);
    } else {
      throw new Error('unexpected next question');
    }
  }
  const navigate = useNavigate();
  const handleConfirm = async (appointment, questions) => {
    try {
      await fetch(`${DJANGO_API}/v2/api/feedback/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          booking_ref: appointment.booking_ref,
        }),
      })
    } catch (e) {
      console.error('unexpected error when calling feedback API. suppressing error to prevent kiosk freeze', e);
    }
    navigate('/print-ticket');
  }

  return (
    <>
      <BookingNumberHeader />
      <div className={QuestionnairePageCSS.questionContainer}>
        {
          loading
            ?
            <h1>{t('questionnaire.loading')}</h1>
            :
            questions.length === 0 ?
              <h1>{t('questionnaire.loadingProblem')}</h1>
              :
              <>
                {activeQuestionId !== null &&
                  <IQuestion
                    key={activeQuestionId}
                    question={questions[activeQuestionId]}
                    onSelect={handleSelect}
                  />
                }

                <div>
                  {
                    <button className={QuestionnairePageCSS.prevQuestionBtn} disabled={isFirstQuestion} onClick={handlePrevQuestion}>
                      <h1>{t('questionnaire.prevQuestion')}</h1>
                    </button>
                  }
                  {!isLastQuestion &&
                    <button className={QuestionnairePageCSS.nextQuestionBtn} disabled={!isAnswered} onClick={handleNextQuestion}>
                      <h1>{t('questionnaire.nextQuestion')}</h1>
                    </button>
                  }
                  {isLastQuestion &&
                    <button className={QuestionnairePageCSS.reviseAnswerBtn} disabled={!isAnswered} onClick={() => handleConfirm(appointment, questions)}>
                      <h1>{t('questionnaire.reviseAnswers')}</h1>
                    </button>
                  }
                </div>
              </>
        }
      </div>
      <Link to="/">
        <div className={QuestionnairePageCSS.backHomeButton}>
          <div className={QuestionnairePageCSS.backHomeButtonText}>
            {t('global.homePage')}
          </div>
        </div>
      </Link>
    </>
  );

}

export default QuestionnairePage;