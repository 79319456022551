import { useState, useEffect } from 'react';
import css from "./TempeturePage.module.css"
import { modelEmotion, modelSpeak, pointMidLeft2, setLive2DScale } from "../../Helper/UnityLive2DController"

import { Link, useNavigate } from 'react-router-dom';
import { useInterval } from './hook';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTemperatureHalf, faMaskFace } from '@fortawesome/free-solid-svg-icons'
import BookingNumberHeader from '../../Component/BookingNumberHeader';

import { KIOSK_API } from '../../Constant/Api';
import { useTranslation } from 'react-i18next';

const TemperaturePage = ({ setSpeech }) => {

  const { t } = useTranslation();

  const getBoxCss = (state) => {
    switch ( true ) {
      case state === false:     return css.failBox;
      case state === true:      return css.passBox;
      case state === undefined:
      case state === null:
      default:                  return css.waitingBox;
    }
  }
  const getPassText = (state) => {
    switch ( true ) {
      case state === false:     return t('temperature.fail');
      case state === true:      return t('temperature.pass');
      case state === undefined:
      case state === null:
      default:                  return t('temperature.waitData');
    }
  }

  // even one of the results say the person pass, it's pass
  const [temperaturePass, setTemperaturePass] = useState(null);
  const [maskPass, setMaskPass] = useState(null);
  const [latestRecordTime, setLatestRecordTime] = useState(null);
  const navigate = useNavigate();

  useInterval(async () => {
    try {
      const response = await fetch(`${KIOSK_API}/temperature/last-measurement`)
      const body = await response.json();
      // console.table(body)
      const { mask, now, serverRecordTime, standard, temperature } = body;
      // all of the properties are string field. need conversion
      const measurementAge_ms = parseInt(now) - parseInt(serverRecordTime);
      const isMaskOn = mask === '1';
      const isTemperatureOk = parseFloat(temperature) < parseFloat(standard);

      if ( measurementAge_ms > 5000 ) {
        console.debug(`measurement too old (more than 5000ms). discard.`);
        console.debug(now, serverRecordTime, parseInt(now) - parseInt(serverRecordTime));
        return;
      }
      setMaskPass( isMaskOn ? true : false );
      setTemperaturePass( isTemperatureOk ? true : false );
      setLatestRecordTime( serverRecordTime ) ;

    } catch (e) {
      console.debug(e);
      return;
    }
  }, 1000);

  function getActionByStatus(temperaturePass, maskPass) {
    
    if ( temperaturePass === false && maskPass === false ) {
      setSpeech(t('temperature.temperatureFail'));
      modelSpeak();
      modelEmotion(0);
      return;
    }

    else if ( temperaturePass === false ) {
      setSpeech(t('temperature.temperatureFail'));
      modelSpeak();
      modelEmotion(0);
      return;
    }

    else if ( maskPass === false ) {
      setSpeech(t('temperature.maskFail'));
      modelSpeak();
      modelEmotion(0);
      return;
    }

    else if ( temperaturePass && maskPass ) {
      setSpeech(t('temperature.passSpeech'));
      modelSpeak();
      modelEmotion(1);
      setTimeout(() => navigate('/questionnaire'), 3000);
      return;
    }

    // default: do nothing
  }

  // Init
  useEffect(() => {
    setSpeech(t('temperature.welcome'));
    setLive2DScale(-0.75, 0.75, 0.75);
    pointMidLeft2();
    modelSpeak();
  }, []);
  
  // when both test pass, setSpeech and proceed to next page

  useEffect(() => {
    console.log(temperaturePass, maskPass);
    getActionByStatus(temperaturePass, maskPass);
  }, [latestRecordTime]);


  return (
    <>
      <BookingNumberHeader />
      <div className = {css.statusContainer}>

        {/* Temperature */}
        <div className = {`${css.Box} ${getBoxCss(temperaturePass)}`}>
          <div className = {css.faIcon}>
            <FontAwesomeIcon icon={faTemperatureHalf} />
          </div>
          <div className = {css.text} >
            {t('temperature.temperatureCheck')}{getPassText(temperaturePass)}
          </div>
        </div>

        {/* Mask */}
        <div className = {`${css.Box} ${getBoxCss(maskPass)}`}>
          <div className = {css.faIcon}>
            <FontAwesomeIcon icon={faMaskFace} />
          </div>
          <div className = {css.text} >
            {t('temperature.maskCheck')}{getPassText(maskPass)}
          </div>
        </div>
      </div>
      <Link to="/">
        <div className={css.backHomeButton}>
          <div className={css.backHomeButtonText}>
            {t('global.homePage')}
          </div>
        </div>
      </Link>
    </>
  );

}

export default TemperaturePage;