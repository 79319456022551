import { UnityLive2DContext } from "../Constant/UnityLive2DContext"

export function pointTopLeft(){
  UnityLive2DContext.send("Live2DManager", "ModelPointTopLeft");
}

// export function pointTopRight(){
//   UnityLive2DContext.send("Live2DManager", "ModelPointTopRight");
// }

export function pointMidLeft(){
  UnityLive2DContext.send("Live2DManager", "ModelPointMidLeft");
}

export function modelBow(){
  UnityLive2DContext.send("Live2DManager", "ModelBow");
}

export function pointBotRight(){
  UnityLive2DContext.send("Live2DManager", "ModelPointBotRight");
}

export function modelIdle(){
  UnityLive2DContext.send("Live2DManager", "ModelIdle");
}

export function pointMidLeft2(){
  UnityLive2DContext.send("Live2DManager", "ModelPointMidLeft2");
}

export function modelSpeak(){
  UnityLive2DContext.send("Live2DManager", "ModelSpeak");
}

export function modelEmotion(emo){
  UnityLive2DContext.send("Live2DManager", "ModelEmotion", emo);
}

export function setLive2DPosition(x, y, z){
  // yes, unityContext.send third arg only accept string|boolean|number :o)
  UnityLive2DContext.send("Live2DManager", "SetLive2DPositionX", x);
  UnityLive2DContext.send("Live2DManager", "SetLive2DPositionY", y);
  UnityLive2DContext.send("Live2DManager", "SetLive2DPositionZ", z);
}

export function setLive2DScale(x, y, z){
  UnityLive2DContext.send("Live2DManager", "SetLive2DScaleX", x);
  UnityLive2DContext.send("Live2DManager", "SetLive2DScaleY", y);
  UnityLive2DContext.send("Live2DManager", "SetLive2DScaleZ", z);
}
