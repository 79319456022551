import { Link, useNavigate } from 'react-router-dom';

import css from './cards.module.css';

export const FlowEntryPointCard = ({ text, to }) => {

  const navigate = useNavigate();

  return (
    <div
      className={css.mainCard}
      onClick={() => navigate(to)}
    >
      <h1>{text}</h1>
    </div>
  );
}

export const WebsiteCard = ({ text, href }) => {
  return (
    <div className={css.card}>
      <h1>{text}</h1>
    </div>
  )
}

export const PosterCard = ({ src, width=1200, height=800 }) => {
  return (
    <div className={css.posterCard} style={{width:width +"px", height:height+"px"}}>
      <img src = {src} style={{width:width +"px", height:height+"px"}}/>
      {/* <img src = {src} className = {css.poster} style={width="120px", height="800px"}/> */}
    </div>
  )
}
