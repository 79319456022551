import React, { useState, useEffect, useRef } from 'react';
import ChooseServicePageCSS from "./ChooseServicePage.module.css"
import { modelIdle, pointTopLeft, resetModel, setLive2DScale } from '../../Helper/UnityLive2DController';
import { FlowEntryPointCard, WebsiteCard, PosterCard } from './cards';
import samplePic from './pic0.jpg';
import samplePic1 from './pic1.jpg';
import { useTranslation } from 'react-i18next';

const ChooseServicePage = ({ setSpeech }) => {

  const [cards, setCards] = useState();
  const mainServiceRef = useRef();
  const { t, i18n } = useTranslation();

  // cards to be dynamically fetched from API
  // TODO: fix dirty way to get component ref
  const testCards = [
    <PosterCard src = {samplePic} key={0} />,
    <div ref = {mainServiceRef}  key={1}>
      <FlowEntryPointCard text={t('chooseService.mainService')} to="/booking"/>
    </div>,
    <PosterCard src = {samplePic1} key={2} width = {600}/>,
    // <WebsiteCard text={'未整(link1)'} key={2} />,
    // <WebsiteCard text={'未整(link2)'} key={3} />,
  ];

  // Init
  useEffect(() => {
    i18n.changeLanguage('zh-Hant');
    setLive2DScale(0.75, 0.75, 0.75);
    modelIdle();
  }, []);

  useEffect(() => {
    setSpeech( t('chooseService.welcomeSpeech') );
    setCards(testCards);
  }, [i18n.language])

  // scroll main service to center
  useEffect(() => {
    if (mainServiceRef.current) {
      mainServiceRef.current.scrollIntoView({inline: 'center'});
    }  
  }, [cards])

  return (
    <>
      <div className = {ChooseServicePageCSS.cardsContainer}>
        {cards}
      </div>

      <div className={ChooseServicePageCSS.i18nButtonContainer}>
        <button type="button" className={ChooseServicePageCSS.i18nButtons} onClick={() => i18n.changeLanguage('en')}>
          <h1>English</h1>
        </button>
        <button type="button" className={ChooseServicePageCSS.i18nButtons} onClick={() => i18n.changeLanguage('zh-Hant')}>
          <h1>繁體中文</h1>
        </button>
        <button type="button" className={ChooseServicePageCSS.i18nButtons} onClick={() => i18n.changeLanguage('zh-Hans')}>
          <h1>简体中文</h1>
        </button>
      </div>
    </>
  );

}

export default ChooseServicePage;