import React, { useState, useEffect } from 'react';

import { Question, IQuestionComponent } from "../question-factory";
import css from "./RadioQuestion.module.css";

const RadioQuestion:IQuestionComponent = ({ question, onSelect:setAnswers }) => {

  const [active, setActive] = useState<number|null>(null);
  useEffect(() => {
    const indexOfChoice = question.answers.indexOf(true);
    indexOfChoice === -1 ? setActive(null) : setActive(indexOfChoice);
  }, []);

  const handleClick = (optionId:number) => {
    setActive(optionId);
  }
  useEffect(() => {
    const answers = question.options.map(() => false);
    if ( active !== null ) {
      answers[active] = true;
      setAnswers(answers);
    }
  }, [active]);

  return (
    <div>
      <h3>{question.questionString}</h3>
      {question.options.map((option, index) =>
        <React.Fragment key={index}>
          <div className={css.inputContainer} onClick={() => handleClick(index)}>
            <input
              type="radio"
              checked={active===index}
              onChange={() => handleClick(index)}
            />
            {/* checkmark display */}
            <div className={css.checkmark}></div>
            {/* question text */}
            <span className={css.questionText}>{option}</span>
          </div>
          <br />
        </React.Fragment>
      )}
    </div>
  )
}
// mimic statuc function in classic React Class: https://www.frankcalise.com/static-methods-in-functional-components
RadioQuestion.isAnswered = (question:Question) => question.answers.indexOf(true) === -1 ? false : true;
RadioQuestion.isThisType = (question:Question) => {
  const { minChoice, maxChoice } = question;
  return minChoice === 1 && maxChoice === 1;
}

export default RadioQuestion;